.custom-head-section{
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    width: 100%;
}
.dropdown-toggle::after {
    display:none;
    content: none;
  }