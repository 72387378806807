// $font-family-heading: 'Nunito', sans-serif;
// $font-family-base: 'Nunito', sans-serif;
$font-family-heading: 'Roboto', sans-serif;
$font-family-base: 'Roboto', sans-serif;
//$font-size-base: .813rem;
$font-size-base: .875rem;


// // LAYOUT VARS
// $topbar-height: 80px;
// $topbar-height-mobile: 70px;

// $sidebar-left-width: 120px;
// $sidebar-left-width-mobile: 90px;
// $sidebar-left-secondary-width: 220px;
// $sidebar-left-secondary-width-mobile: 190px;
// $side-clearance: 20px;

// GLOBAL COLORS
$facebook: rgb(55, 101, 201);
$google: rgb(236, 65, 44);
$twitter: rgb(3, 159, 245);
$instagram:rgb(193,53,132);
$linkedin:rgb(0,119,181);
$dribble:#ea4c89;
$youtube: #c4302b;

$headerLight: #FFF;
$headerLightHover: rgb(230, 230, 230);

$headerMenulight: #F9FBFB;
$textLightVersion: #708095;
$iconLightVersion: #9DABBE;

$blueAccent: #315DD1;
$headerColorText: #4f4f4f;
$pricingCounter: #FBFCFD;

$menuTextColor: #FFF;

$catalinaBlue: #072572;
$brightGray: #E9EFF4;
$pastelBlue: #B6BED4;
$lavender: #E6EDFF;
$unBlue: #6A89DC;
$lightSlateBlue: #727FA1;
$oxfordBlue: #021C4F;

$omniCloudPrimary: $blueAccent; //new color based on new design
$omniCloudSidebar: #041C4F; //new color based on new design
$omniBackground: #F4F6FC;
// $omniCloudPrimary: #041C4F; //new color based on new design
// $omniCloudPrimary: #663399;
// $omniCloudPrimary: #003473;
// $omniCloudPrimary: #276493;

:root {
  --color-main: #305DD2;
  --color-white: #fff;
	--color-sidebar: #041C4F;
	--color-active-pagination: #041C4F;
	--color-topbar: #fff;
	--color-topbar-element: #727FA1;
	--color-accent: #305DD2;
	--color-bg-color: #F4F6FC;
	--color-card-label: #072572;
	--color-border: #E9EFF4;
  --color-search-bar: #F9FBFB;
	--color-table-header: #F9FBFB;
	--color-table-title: #021C4F;
	--color-table-border: #E9EFF4;
  --color-table-color: #fff;
  --color-table-hover: #F1F7FF;
  --color-table-dropdown: #fff;
  --color-table-dropdown-text: #727FA1;
	--color-table-text-color: #727FA1;
  --color-omni-header: #072572;
  --color-omni-subtitle: #727FA1;
  --color-tab-link: #021C4F;
  --color-hardware-svg: #b6bed4;
  --color-hardware-pill-bg: #E6EDFF;
  --color-hardware-pill-header: #fff;
  --color-card-text-color: #727FA1;
  --color-card-text-color-active: #305DD2;
  --color-card-mo-price: #305DD2;
	--color-card-text-color: #305DD2;
  --color-toggle-switcher: #B6BED4;
  --color-popover-text: #0D0D19;
  --color-popover-bg: #F9FBFB;
	--color-pricing-counter: #FBFCFD;
  --color-pagination: #727FA1;
  accent-color: var(--color-main);
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-sidebar: #0D0D19;
    --color-active-pagination: #305DD2;
    --color-topbar: #1E1D2B;
    --color-topbar-element: #fff;
    --color-accent: #305DD2;
    --color-bg-color: #161523;
    --color-card-label: #E9EFF4;
    --color-border: #E9EFF4;
    --color-search-bar: #252432;
    --color-table-header: #1E1D2B;
    --color-table-title: #fff;
    --color-table-border: #313049;
    --color-table-color: #252432;
    --color-table-hover: #363545;
    --color-table-dropdown: #161523;
    --color-table-dropdown-text: #fff;
    --color-table-text-color: #fff;
    --color-omni-header: #E9EFF4;
    --color-omni-subtitle: #727FA1;
    --color-tab-link: #8590AD;
    --color-hardware-svg: #727FA1;
    --color-hardware-pill-bg: #2E2E49;
    --color-hardware-pill-header: #fff;
    --color-card-text-color: #727FA1;
    --color-card-text-color-active: #fff;
    --color-card-mo-price: #727FA1;
    --color-toggle-switcher: #605E7B;
    --color-popover-bg: #3a3a41;
    --color-popover-text: #fff;
    --color-pricing-counter: #1E1D2B;
  }
}